app.factory('authorizationInterceptor', [
    '$q',function ($q) {
		
        var service = {
			'request': function (requestSuccess) {
				requestSuccess.headers = requestSuccess.headers || {};

	            //if (localStorageService.get("authorizationData") !== "") {
		           // requestSuccess.headers.Authorization = 'Bearer ' + localStorageService.get("authorizationData");
	            //}

	            return requestSuccess || $q.when(requestSuccess);
            },
            'responseError': function (rejection) {
	            console.log("console.log(responseFailure);");
	            console.log(responseFailure);
				if (rejection.status === 403) {
		            alert("forbidden");
		            window.location = "https://localhost:44347/forbidden";
		            window.href = "forbidden";

				} else if (rejection.status === 401) {

		            alert("unauthorized");
					rejection.set("authorizationData", "");
	            }

				return this.q.reject(rejection);
            }
        };

        return service;
    }
]);